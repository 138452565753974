<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-select
          clearable
          :options="statusOps"
          v-model="searchParam.quStatus"
          @change="$refs.list.search()"
          label="问卷状态"
        />
        <v-select
          clearable
          :options="shelfStsOps"
          v-model="searchParam.shelfSts"
          @change="$refs.list.search()"
          label="发布状态"
        />

        <!-- <v-select
          clearable
          :options="sortFieldOps"
          v-model="searchParam.sortField"
          @change="$refs.list.search()"
          label="话题排序"
        /> -->
        <!-- <v-input label="话题标题" v-model="searchParam.topicTitle" />
        <v-date-picker
          label="创建时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="创建开始时间"
          endPlaceholder="创建结束时间"
        /> -->
      </template>
      <template #operateSlot="scope">
        <v-button
          text="问卷二维码"
          type="text"
          v-if="scope.row.qrcodeUrl"
          @click="showQrCode(scope.row.qrcodeUrl)"
        />
        <v-button
          text="编辑"
          type="text"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          @click="toEdit(scope.row)"
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
        <v-button
          :text="shelfStsBtnMap[scope.row.shelfSts]"
          type="text"
          @click="
            toChangeShelfSts(scope.row, shelfStsBtnMap[scope.row.shelfSts])
          "
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getTopicListURL, topicDeleteURL, topicAddOrEditURL } from "./api.js";
import { statusMap, setStatusOps, shelfStsMap, setShelfStsOps } from "./map.js";
import {
  createAlinkVNode,
  createImgVNode,
  createHTMLVNode,
} from "@/utils/utils.js";

export default {
  name: "topicList",
  data() {
    return {
      searchParam: {
        communityId: null,
        quStatus: undefined,
        quType: 1,
        shelfSts: undefined,
      },
      shelfStsBtnMap: {
        0: "发布",
        1: "下架",
        2: "发布",
      },
      statusOps: setStatusOps(),
      shelfStsOps: setShelfStsOps(),
      tableUrl: getTopicListURL,
      headers: [
        {
          prop: "imageUrls",
          label: "问卷封面图",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "allSpaces",
          label: "调查范围",
          formatter(row, prop) {
            // 1-是，0-否
            return row[prop] === 1 ? "全部园区" : "部分园区";
          },
        },
        {
          prop: "title",
          label: "问卷标题",
        },
        {
          prop: "answerCount",
          label: "参与数量",
          formatter: (row, prop) => {
            return createAlinkVNode(this, row, prop, {
              cb: this.toJoinList,
            });
          },
        },
        {
          prop: "time",
          label: "问卷有效时间",
          formatter: (row, prop) => {
            return createHTMLVNode(
              this,
              `${row.startDate}~<br/>${row.endDate}`,
              row,
              prop,
              { color: "#606266" }
            );
          },
        },
        {
          prop: "quStatus",
          label: "问卷状态",
          formatter: (row, prop) => {
            // 1:未开始 2:进行中 3:已结束
            if (row.shelfSts == 0) {
              return statusMap[1];
            } else {
              return statusMap[row[prop]] || "--";
            }
          },
        },
        {
          prop: "shelfSts",
          label: "发布状态",
          formatter(row, prop) {
            // 0-待发布 1-已发布 2-已下架
            return shelfStsMap[row[prop]] || "--";
          },
        },
      ],
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.createTimeS && !this.searchParam.createTimeE
          ? []
          : [this.searchParam.createTimeS, this.searchParam.createTimeE];
      },
      set(val) {
        [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
          "",
          "",
        ];
      },
    },
  },
  mounted() {},
  methods: {
    showQrCode(url) {
      // 活动二维码
      window.open(url);
    },
    toAdd() {
      this.$router.push({
        name: "questionnaireEdit",
        query: {
          isView: false,
        },
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "questionnaireEdit",
        query: {
          id: row.id,
          isView: row.quStatus === 3, // 调查问卷状态为已结束
        },
      });
    },
    toDelete(row) {
      this.$confirm("是否确认删除？", "确定", {
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(`${topicDeleteURL}`, null, {
              params: {
                topicId: row.id,
              },
            })
            .then((res) => {
              if (res.code === 200) {
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    toJoinList(row) {
      this.$router.push({
        name: "questionAnswerList",
        query: {
          id: row.id,
        },
      });
    },

    toChangeShelfSts(row, str) {
      this.$confirm(`是否确认${str}？`, "确定", {
        type: "warning",
      }).then(() => {
        // 0-待上架 1-上架 2-下架
        this.$axios
          .post(`${topicAddOrEditURL}`, {
            shelfSts: row.shelfSts === 1 ? 2 : 1,
            id: row.id,
          })
          .then((res) => {
            if (res.code === 200) {
              this.$refs.list.search();
            }
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
